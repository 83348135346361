import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";

import { setModoTela, setFiltro, getLista } from "./integracaoActions";

class Integracao extends Component {
  state = {
    linhaSelecionada: null,
  };

  componentWillMount() {
    this.props.getLista();
  }

  render() {
    return (
      <ContentCard>
        <ContentCardBody>
          {this.props.filtro.id_integracao ? (
            <Row alignRight>
              <Grid
                cols="12 6 4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "15px",
                }}
              >
                <Button
                  text="Voltar"
                  type={"warning"}
                  icon={"fa fa-chevron-left"}
                  event={() => {
                    console.log(this.props.filtro.id_integracao);
                    this.props.setFiltro({
                      ...this.props.filtro,
                      id_integracao: null,
                    });
                    this.props.getLista();
                  }}
                />
              </Grid>
            </Row>
          ) : null}
          <Table responsive>
            <THead>
              <Tr>
                <Th
                  colspan={1}
                  verticalAlign="top"
                  paddingLeft={20}
                  paddingBottom={10}
                  paddingTop={20}
                >
                  Sindicato
                </Th>
                <Th
                  colspan={1}
                  alignCenter
                  verticalAlign="top"
                  maxWidth={70}
                  paddingBottom={10}
                  paddingTop={20}
                >
                  Status
                </Th>
                {this.props.filtro.id_integracao ? (
                  <Th
                    colspan={1}
                    verticalAlign="top"
                    maxWidth={70}
                    paddingBottom={10}
                    paddingTop={20}
                  >
                    Tipo de Erro
                  </Th>
                ) : null}
                {this.props.filtro.id_integracao ? (
                  <Th
                    colspan={1}
                    verticalAlign="top"
                    maxWidth={70}
                    paddingBottom={10}
                    paddingTop={20}
                  >
                    Descrição do Erro
                  </Th>
                ) : null}
                {this.props.filtro.id_integracao ? (
                  <Th
                    colspan={1}
                    verticalAlign="top"
                    maxWidth={70}
                    paddingBottom={10}
                    paddingTop={20}
                  >
                    Objeto enviado
                  </Th>
                ) : null}
                {this.props.filtro.id_integracao ? (
                  <Th
                    colspan={1}
                    verticalAlign="top"
                    paddingBottom={10}
                    paddingTop={20}
                  >
                    Data e Hora
                  </Th>
                ) : (
                  <>
                    <Th
                      colspan={1}
                      verticalAlign="top"
                      paddingBottom={10}
                      paddingTop={20}
                    >
                      Data e Hora (último sócio)
                    </Th>
                    <Th
                      colspan={1}
                      verticalAlign="top"
                      paddingBottom={10}
                      paddingTop={20}
                    >
                      Data e Hora (último conjuge)
                    </Th>
                    <Th
                      colspan={1}
                      verticalAlign="top"
                      paddingBottom={10}
                      paddingTop={20}
                    >
                      Data e Hora (última mensalidade)
                    </Th>
                  </>
                )}
                {this.props.filtro.id_integracao ? null : (
                  <Th
                    colspan={1}
                    verticalAlign="top"
                    paddingBottom={10}
                    paddingTop={20}
                  >
                    Detalhes do último mês
                  </Th>
                )}
              </Tr>
            </THead>
            <TBody>
              {this.props.lista.map((item) => (
                <Tr
                  key={item.id}
                  className={`${
                    this.state.linhaSelecionada == item.id ? "hover" : ""
                  }`}
                  onClick={() =>
                    this.setState({ ...this.state, linhaSelecionada: item.id })
                  }
                  onMouseEnter={() =>
                    this.setState({ ...this.state, linhaSelecionada: item.id })
                  }
                >
                  <Td minWidth={120} paddingLeft={20} noWrap>
                    {item.descricao_sindicato}
                  </Td>

                  <Td alignCenter paddingRight={20}>
                    {item.status ? (
                      <input
                        type="radio"
                        checked
                        style={{
                          accentColor: "rgb(62 255 62)",
                        }}
                        readOnly
                      />
                    ) : (
                      <input
                        type="radio"
                        checked
                        style={{ accentColor: "#f56969" }}
                        readOnly
                      />
                    )}
                  </Td>

                  {this.props.filtro.id_integracao ? (
                    item.tipo_erro ? (
                      <Td minWidth={150} colspan={1} noWrap>
                        {item.tipo_erro}
                      </Td>
                    ) : (
                      <Td paddingLeft={40} colspan={1}>
                        -
                      </Td>
                    )
                  ) : null}
                  {this.props.filtro.id_integracao ? (
                    item.descricao_erro ? (
                      <Td minWidth={100} colspan={1} noWrap>
                        {item.descricao_erro}
                      </Td>
                    ) : (
                      <Td paddingLeft={60} colspan={1}>
                        -
                      </Td>
                    )
                  ) : null}
                  {this.props.filtro.id_integracao ? (
                    <Td colspan={1}>
                      <a
                        href={item.href}
                        onClick={() => alert(item.objeto_enviado)}
                        onMouseEnter={(e) => {
                          e.target.style.color = "blue";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "black";
                        }}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Visualizar
                      </a>
                    </Td>
                  ) : null}
                  {this.props.filtro.id_integracao ? (
                    <Td>
                      {item.data_hora
                        ? new Date(item.data_hora)
                            .toLocaleString({
                              dateStyle: "short",
                              timeStyle: "medium",
                            })
                            .replace(",", "")
                        : " - "}
                    </Td>
                  ) : (
                    <>
                      <Td>
                        {item.data_hora_socio
                          ? new Date(item.data_hora_socio)
                              .toLocaleString({
                                dateStyle: "short",
                                timeStyle: "medium",
                              })
                              .replace(",", "")
                          : " - "}
                      </Td>
                      <Td>
                        {item.data_hora_conjuge
                          ? new Date(item.data_hora_conjuge)
                              .toLocaleString({
                                dateStyle: "short",
                                timeStyle: "medium",
                              })
                              .replace(",", "")
                          : " - "}
                      </Td>
                      <Td>
                        {item.data_hora_mensalidade
                          ? new Date(item.data_hora_mensalidade)
                              .toLocaleString({
                                dateStyle: "short",
                                timeStyle: "medium",
                              })
                              .replace(",", "")
                          : " - "}
                      </Td>
                    </>
                  )}

                  {this.props.filtro.id_integracao ? null : (
                    <Td>
                      <a
                        href={item.href}
                        onClick={() => {
                          this.props.setFiltro({
                            ...this.props.filtro,
                            id_integracao: item.id,
                          });
                          this.props.getLista();
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.color = "blue";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "black";
                        }}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Detalhes
                      </a>
                    </Td>
                  )}
                </Tr>
              ))}
            </TBody>
          </Table>
        </ContentCardBody>
      </ContentCard>
    );
  }
}

const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  modoTela: state.integracao.modoTela,
  filtro: state.integracao.filtro,
  lista: state.integracao.lista,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModoTela,
      setFiltro,
      getLista,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Integracao);
