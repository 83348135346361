import React from "react";
import Grid from "../layout/grid";

export default (props) => {
  return (
    <Grid cols={props.cols}>
      <div className="form-group checkbox">
        <label htmlFor={props.name} style={{ fontWeight: "bold" }}>
          {props.onChange ? (
            <input
              {...props.input}
              style={{ ...props.style, marginRight: 4 }}
              placeholder={props.placeholder}
              readOnly={props.readOnly}
              type="checkbox"
              onChange={props.onChange}
              checked={props.value}
            />
          ) : (
            <input
              {...props.input}
              placeholder={props.placeholder}
              readOnly={props.readOnly}
              type="checkbox"
              title={props.label}
              checked={props.input.value == true}
            />
          )}
          <span style={{ marginLeft: 10 }}>{props.label}</span>
        </label>
      </div>
    </Grid>
  );
};
