const INITIAL_STATE = { registro: {}, lista: [] };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ERRO_INTEGRACAOO_TELA":
      return {
        ...state,
        modoTela: action.payload.modo,
        registro: action.payload.registro,
      };

    case "ERRO_INTEGRACAO_LISTADO":
      return { ...state, lista: action.payload.data };

    default:
      return state;
  }
};
