import React, { Component } from "react";
import { connect } from "react-redux";
import MenuItem from "./menuItem";
import { withRouter } from "react-router-dom";

class Menu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let selecionado = "";
    if (this.props.location && this.props.location.pathname) {
      selecionado = this.props.location.pathname;
    }

    return (
      <nav className="mt-2">
        <ul
          className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <MenuItem
            path="/"
            label="Integração"
            icon="fas fa-share-alt"
            active={selecionado == "/"}
          />

          <MenuItem
            path="erroIntegracao"
            label="Erros de Integração"
            icon="fas fa-exclamation-triangle"
            active={selecionado == "/erroIntegracao"}
          />

          <MenuItem
            path="usuario"
            label="Usuários"
            icon="fas fa-users"
            active={selecionado == "/usuario"}
          />
          <MenuItem
            path="sindicatoAcesso"
            label="Sindicatos"
            icon="fas fa-cogs"
            active={selecionado == "/sindicatoAcesso"}
          />
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default withRouter(connect(mapStateToProps)(Menu));
