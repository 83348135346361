import axios from "axios";
import consts from "../consts";

export function setModoTela(modo, registro = {}) {
  return {
    type: "INTEGRACAO_MODO_TELA",
    payload: {
      modo: modo,
      registro: registro,
    },
  };
}

export function setFiltro(filtro) {
  return {
    type: "INTEGRACAO_FILTRO",
    payload: filtro,
  };
}

export function getLista() {
  return (dispatch, getState) => {
    const rota = getState().integracao.filtro.id_integracao
      ? `integracaoLog?id_integracao=${
          getState().integracao.filtro.id_integracao
        }`
      : "integracao";
    axios
      .get(`${consts.API_URL}/${rota}`)
      .then((resp) => {
        dispatch({
          type: "INTEGRACAO_LISTADO",
          payload: resp,
        });
      })
      .catch((ex) => {
        console.log(ex);
      });
  };
}
