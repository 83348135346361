import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";

import AuthReducer from "../auth/authReducer";
import TabReducer from "../common/tab/tabReducer";
import UsuarioReducer from "../usuario/usuarioReducer";
import ErroIntegracaoReducer from "../erroIntegracao/erroIntegracaoReducer";
import IntegracaoReducer from "../integracao/integracaoReducer";
import SindicatoReducer from "../sindicato/sindicatoReducer";

const rootReducer = combineReducers({
  auth: AuthReducer,
  usuario: UsuarioReducer,
  integracao: IntegracaoReducer,
  erroIntegracao: ErroIntegracaoReducer,
  sindicato: SindicatoReducer,
  tab: TabReducer,
  form: formReducer,
  toastr: toastrReducer,
});

export default rootReducer;
