import React from "react";

export default (props) => (
  <td
    {...props}
    align={props.alignRight ? "right" : props.alignCenter ? "center" : "left"}
    onClick={props.onClick}
    title={props.noWrap ? props.children : null}
    style={{
      paddingLeft: props.paddingLeft || 2,
      paddingTop: props.paddingTop || 2,
      paddingRight: 8,
      paddingBottom: props.paddingBottom || 2,
      verticalAlign: "middle",
      height: props.height || 40,
      minWidth: props.minWidth || 0,
      color: props.color || "#000",
      backgroundColor: props.backgroundColor,
      maxWidth: props.noWrap ? 0 : null,
      overflow: props.noWrap ? "hidden" : null,
      textOverflow: props.noWrap ? "ellipsis" : null,
      whiteSpace: props.noWrap ? "nowrap" : null,
      fontSize: props.small ? 11 : null,
      border: props.border,
      borderLeft: props.borderLeft,
      borderRight: props.borderRight,
      borderTop: props.borderTop,
      borderBottom: props.borderBottom,
    }}
  >
    {props.children}
  </td>
);
