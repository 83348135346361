import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";

import { setModoTela, getLista } from "./erroIntegracaoActions";
import { Select } from "react-select";

class ErroIntegracao extends Component {
  state = {
    linhaSelecionada: null,
  };

  componentWillMount() {
    this.props.getLista("CONJUGE");
  }

  render() {
    return (
      <ContentCard>
        <ContentCardBody>
          <select
            className="form-control"
            onChange={(e) => {
              this.props.getLista(e.target.value);
            }}
          >
            <option value="SOCIO">Sócio</option>
            <option value="CONJUGE" selected>
              Conjuge
            </option>
            <option value="MENSALIDADE">Mensalidade</option>
          </select>
          <Table responsive>
            <THead>
              <Tr>
                <Th
                  colspan={1}
                  paddingBottom={10}
                  paddingTop={20}
                  paddingLeft={20}
                >
                  Motivo do Erro
                </Th>

                <Th colspan={1} paddingBottom={10} paddingTop={20}></Th>
              </Tr>
            </THead>
            <TBody>
              {this.props.lista.map((item, index) => (
                <Tr
                  key={index}
                  className={`${
                    this.state.linhaSelecionada == index ? "hover" : ""
                  }`}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      linhaSelecionada: index,
                    })
                  }
                  onMouseEnter={() =>
                    this.setState({
                      ...this.state,
                      linhaSelecionada: index,
                    })
                  }
                >
                  <Td paddingLeft={20}>
                    {item.motivo}
                    <p style={{ margin: 0 }}></p>

                    {item.registro_com_erro}
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </ContentCardBody>
      </ContentCard>
    );
  }
}

const mapStateToProps = (state) => ({
  modoTela: state.erroIntegracao.modoTela,
  lista: state.erroIntegracao.lista,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setModoTela, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ErroIntegracao);
