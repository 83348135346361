import axios from "axios";
import consts from "../consts";

export function setModoTela(modo, registro = {}) {
  return {
    type: "ERRO_INTEGRACAO_MODO_TELA",
    payload: {
      modo: modo,
      registro: registro,
    },
  };
}

export function getLista(tipo) {
  return (dispatch, getState) => {
    axios
      .get(`${consts.API_URL}/integracaoLog/erros?tipo=${tipo}`)
      .then((resp) => {
        console.log(resp);
        dispatch({
          type: "ERRO_INTEGRACAO_LISTADO",
          payload: resp,
        });
      })
      .catch((ex) => {
        console.log(ex);
      });
  };
}
