const INITIAL_STATE = {
  registro: {},
  modoTela: "lista",
  filtro: {
    id_integracao: null,
  },
  lista: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "INTEGRACAO_MODO_TELA":
      return {
        ...state,
        modoTela: action.payload.modo,
        registro: action.payload.registro,
      };

    case "INTEGRACAO_FILTRO":
      return { ...state, filtro: action.payload };

    case "INTEGRACAO_LISTADO":
      return {
        ...state,
        lista: action.payload.data,
      };

    default:
      return state;
  }
};
