import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputMask from "../common/form/labelAndInputMask";

import { setModoTela, initForm } from "./sindicatoActions";
import labelAndCheckbox from "../common/form/labelAndCheckbox";

class SindicatoForm extends Component {
  state = {};

  componentWillMount() {}

  render() {
    let readOnly = this.props.excluir ? "readOnly" : "";

    return (
      <ContentCard>
        <Form event={this.props.handleSubmit}>
          <ContentCardBody>
            <Row>
              <Field
                name="codigo"
                component={LabelAndInput}
                label="Código"
                placeholder="Informe o código"
                cols="12 12 12 12"
                readOnly={readOnly}
              />

              <Field
                name="nome"
                component={LabelAndInput}
                label="Nome"
                placeholder="Informe o nome"
                cols="12 12 12 12"
                readOnly={readOnly}
              />

              <Field
                name="telefone"
                component={LabelAndInputMask}
                label="Telefone"
                placeholder="Informe o telefone"
                cols="12 12 12 12"
                mask="(99) 99999-9999"
                readOnly={readOnly}
              />

              <Field
                name="email"
                component={LabelAndInput}
                label="E-mail"
                placeholder="Informe o e-mail"
                cols="12 12 12 12"
                readOnly={readOnly}
              />

              <Field
                name="cidade"
                component={LabelAndInput}
                label="Cidade"
                placeholder="Informe a cidade"
                cols="12 12 12 12"
                readOnly={readOnly}
              />

              <Field
                name="endereco"
                component={LabelAndInput}
                label="Endereço"
                placeholder="Informe o endereço"
                cols="12 12 12 12"
                readOnly={readOnly}
              />

              <Field
                name="data_base_mensalidade"
                component={LabelAndInputMask}
                label="Data base das mensalidades"
                placeholder="Informe a partir de qual data deve buscar as mensalidades"
                cols="12 12 12 12"
                mask="99/99/9999"
                readOnly={readOnly}
              />

              <Field
                name="host"
                component={LabelAndInput}
                label="Host"
                placeholder="Informe o host do banco de dados"
                cols="12 12 12 12"
                readOnly={readOnly}
              />

              <Field
                name="nome_banco_de_dados"
                component={LabelAndInput}
                label="Nome do Banco de Dados"
                placeholder="Informe o nome do banco de dados"
                cols="12 12 12 12"
                readOnly={readOnly}
              />

              <Field
                name="porta"
                component={LabelAndInput}
                label="Porta"
                placeholder="Informe a porta do banco de dados"
                cols="12 12 12 12"
                readOnly={readOnly}
              />

              <Field
                name="usuario"
                component={LabelAndInput}
                label="Usuario"
                placeholder="Informe o usuario de acesso ao banco de dados"
                cols="12 12 12 12"
                readOnly={readOnly}
              />

              <Field
                name="senha"
                component={LabelAndInput}
                label="Senha"
                placeholder="Informe o senha de acesso ao banco de dados"
                cols="12 12 12 12"
                readOnly={readOnly}
              />
              <Field
                name="bloqueado"
                component={labelAndCheckbox}
                label="Bloqueado"
                cols="12 12 12 12"
                readOnly={readOnly}
              />
            </Row>
          </ContentCardBody>
          <ContentCardFooter>
            <Row alignCenter>
              <Grid cols="6 6 4 3">
                {this.props.excluir ? (
                  <Button
                    text="Excluir"
                    submit
                    type={"danger"}
                    icon={"fa fa-trash"}
                  />
                ) : (
                  <Button
                    text="Salvar"
                    submit
                    type={"success"}
                    icon={"fa fa-check"}
                  />
                )}
              </Grid>
              <Grid cols="6 6 4 3">
                <Button
                  text="Voltar"
                  type={"warning"}
                  icon={"fa fa-chevron-left"}
                  event={() => this.props.setModoTela("lista")}
                />
              </Grid>
            </Row>
          </ContentCardFooter>
        </Form>
      </ContentCard>
    );
  }
}

SindicatoForm = reduxForm({ form: "sindicatoForm", destroyOnUnmount: false })(
  SindicatoForm
);
const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  formularioValues: getFormValues("sindicatoForm")(state),
  registro: state.sindicato.registro,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModoTela,
      initForm,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(SindicatoForm);
