import React from "react";
import { Switch, Route, Redirect } from "react-router";

import Usuario from "../usuario/usuario";
import Integracao from "../integracao/integracao";
import ErroIntegracao from "../erroIntegracao/erroIntegracao";
import Sindicato from "../sindicato/sindicato";

export default (props) => (
  <div id="id-content-wrapper" className="content-wrapper">
    <Switch>
      <Route exact path="/" component={Integracao} />
      <Route path="/erroIntegracao" component={ErroIntegracao} />
      <Route path="/usuario" component={Usuario} />
      <Route path="/sindicatoAcesso" component={Sindicato} />

      <Redirect from="*" to="/" />
    </Switch>
  </div>
);
