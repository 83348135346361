import React, { Component } from "react";
import { HashRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../common/template/header";
import SideBar from "../common/template/sideBar";
import Messages from "../common/msg/messages";
import imagemLogo from "../assets/images/logo_fetag.jpg";

import Routes from "./routes";

class App extends Component {
  state = {
    carregando: true,
  };

  constructor(props) {
    super(props);

    setTimeout(() => {
      this.setState({ ...this.state, carregando: false });
    }, 2000);
  }

  render() {
    return (
      <HashRouter>
        {this.state.carregando ? (
          <div
            class="modal fade show"
            id="modal-overlay"
            style={{ display: "block", top: "40%" }}
            aria-modal="true"
            role="dialog"
          >
            <div class="modal-dialog">
              <div class="overlay">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={imagemLogo} style={{ width: 100 }} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="wrapper">
            <Header />
            <SideBar />
            <Routes />
            <Messages />
          </div>
        )}
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
});
export default connect(mapStateToProps, null)(App);
