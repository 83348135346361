import "./auth.css";
import React, { Component } from "react";
import { reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login, initForm } from "./authActions";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import imagemLogo from "../assets/images/logo_fetag.jpg";

class Auth extends Component {
  getStyles() {
    return makeStyles((theme) => ({
      paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: "100%",
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
    }));
  }

  getTheme() {
    return createMuiTheme({
      palette: {
        primary: {
          // light: será calculada com base em palette.primary.main,
          main: "#28a745",
          // dark: será calculada com base em palette.primary.main,
          // contrastText: será calculada para contrastar com palette.primary.main
        },
        secondary: {
          light: "#0066ff",
          main: "#0044ff",
          // dark: será calculada com base palette.secondary.main,
          contrastText: "#ffcc00",
        },
        // Usado por `getContrastText()` para maximizar o contraste entre
        // o plano de fundo e o texto.
        contrastThreshold: 3,
        // Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
        // dois índices dentro de sua paleta tonal.
        // Por exemplo, mude de Red 500 para Red 300 ou Red 700.
        tonalOffset: 0.2,
      },
    });
  }

  render() {
    const classes = this.getStyles();
    const { handleSubmit } = this.props;

    return (
      <ThemeProvider theme={this.getTheme()}>
        <div style={{ backgroundColor: "#28a745", height: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100vh",
              backgroundSize: "cover",
            }}
          >
            {window.screen.width > 800 ? (
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper} style={{ textAlign: "center" }}>
                  {/*<img src={imagemLogin} style={{ width: (window.screen.width * 600) / 1700, marginBottom: 20 }}/>*/}
                  <h1 style={{ color: "#fff", textAlign: "center" }}>
                    Integração
                  </h1>
                  <h1 style={{ color: "#fff", textAlign: "center" }}>Fetag</h1>
                </div>
                <Snackbar open={this.props.message.open}>
                  <MuiAlert severity={this.props.message.type} variant="filled">
                    {this.props.message.text}
                  </MuiAlert>
                </Snackbar>
              </Container>
            ) : null}
            {window.screen.width > 800 ? (
              <Container
                component="main"
                style={{
                  height: "80%",
                  width: 2,
                  display: "flex",
                  alignItems: "center",
                  borderRight: "2px solid #fff",
                }}
              />
            ) : null}
            <Container component="main" maxWidth="xs" style={{ width: "100%" }}>
              <CssBaseline />
              {/*<h1 style={{ color: '#fff', textAlign: 'center', paddingBottom: 20 }}>S I G</h1>*/}
              <div
                className={classes.paper}
                style={{
                  backgroundColor: "#fff",
                  paddingLeft: 16,
                  paddingTop: 40,
                  paddingRight: 16,
                  paddingBottom: 40,
                  borderRadius: 10,
                  textAlign: "center",
                }}
              >
                <img
                  src={imagemLogo}
                  alt="Logo Fetag"
                  style={{ width: 300, marginBottom: 10 }}
                />

                <form
                  className={classes.form}
                  noValidate
                  onSubmit={handleSubmit((v) => this.props.login(v))}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="login"
                    label="Usuário"
                    name="login"
                    autoComplete="login"
                    autoFocus
                    onChange={(e) => {
                      this.props.initForm({
                        ...this.props.formularioValues,
                        login: e.target.value,
                      });
                    }}
                  />

                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="senha"
                    label="Senha"
                    type="password"
                    id="senha"
                    autoComplete="current-password"
                    onChange={(e) => {
                      this.props.initForm({
                        ...this.props.formularioValues,
                        senha: e.target.value,
                      });
                    }}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Entrar
                  </Button>
                </form>
              </div>
              <Snackbar open={this.props.message.open}>
                <MuiAlert severity={this.props.message.type} variant="filled">
                  {this.props.message.text}
                </MuiAlert>
              </Snackbar>
            </Container>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

Auth = reduxForm({ form: "authForm" })(Auth);
const mapStateToProps = (state) => ({
  message: state.auth.message,
  formularioValues: getFormValues("authForm")(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ login, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
