import React from "react";

export default (props) => (
  <th
    {...props}
    className={props.acoes ? "table-actions" : ""}
    style={{
      textAlign: props.alignRight
        ? "right"
        : props.alignCenter
        ? "center"
        : "left",
      width: props.width || null,
      height: props.height || null,
      paddingLeft: props.paddingLeft || 2,
      backgroundColor: props.backgroundColor || "#fff",
      color: props.color || "#000",
      paddingTop: props.paddingTop || 20,
      paddingBottom: props.paddingBottom || 10,
      paddingRight: props.paddingRight || 0,
      borderLeft: props.borderLeft,
      borderRight: props.borderRight,
      borderTop: props.borderTop,
      borderBottom: props.borderBottom,
      fontSize: props.small ? 11 : null,
    }}
  >
    {props.children}
  </th>
);
