import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./sindicatoForm";
import DateFormat from "../common/dateFormat/DateFormat";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";

import {
  setModoTela,
  initForm,
  salvar,
  excluir,
  getLista,
} from "./sindicatoActions";

class Sindicato extends Component {
  state = {
    linhaSelecionada: null,
  };

  componentWillMount() {
    this.props.getLista();
  }

  render() {
    return (
      <div>
        <Content>
          {this.props.modoTela == "cadastro" ? (
            <Form onSubmit={this.props.salvar} />
          ) : null}
          {this.props.modoTela == "exclusao" ? (
            <Form excluir onSubmit={this.props.excluir} />
          ) : null}
          {this.props.modoTela == "lista" ? this.lista() : null}
        </Content>
      </div>
    );
  }

  lista() {
    return (
      <ContentCard>
        <ContentCardHeader>
          <Row>
            <Grid cols="6 6 4 2">
              <Button
                text="Adicionar"
                type={"success"}
                icon={"fa fa-plus"}
                event={() => {
                  this.props.setModoTela("cadastro", {});
                  this.props.initForm({});
                }}
              />
            </Grid>
          </Row>
        </ContentCardHeader>
        <ContentCardBody>
          <Table responsive>
            <THead>
              <Tr>
                <Th small paddingLeft={20}>
                  Código
                </Th>
                <Th small>Nome</Th>
                <Th small>Telefone</Th>
                <Th small>E-mail</Th>
                <Th small>Cidade</Th>
                <Th small>Endereço</Th>
                <Th small paddingLeft={10}>
                  Data Base Mensalidades
                </Th>
                <Th small>Host</Th>
                <Th small>Nome do Banco de Dados</Th>
                <Th small>Porta</Th>
                <Th small>Usuário</Th>
                <Th small>Senha</Th>
                <Th small>Bloqueado</Th>
                <Th small></Th>
              </Tr>
            </THead>
            <TBody>
              {this.props.lista.map((item) => (
                <Tr
                  key={item.id}
                  className={`${
                    this.state.linhaSelecionada == item.id ? "hover" : ""
                  }`}
                  onClick={() =>
                    this.setState({ ...this.state, linhaSelecionada: item.id })
                  }
                  onMouseEnter={() =>
                    this.setState({ ...this.state, linhaSelecionada: item.id })
                  }
                >
                  <Td small paddingLeft={20}>
                    {item.codigo}
                  </Td>
                  <Td small minWidth={100} noWrap>
                    {item.nome}
                  </Td>
                  <Td small noWrap minWidth={80}>
                    {item.telefone}
                  </Td>
                  <Td small minWidth={80} noWrap>
                    {item.email}
                  </Td>
                  <Td small noWrap minWidth={80}>
                    {item.cidade}
                  </Td>
                  <Td small noWrap minWidth={80}>
                    {item.endereco}
                  </Td>
                  <Td small minWidth={60} noWrap paddingLeft={10}>
                    {DateFormat.formatarDataSqlParaTela(
                      item.data_base_mensalidade
                    )}
                  </Td>
                  <Td small minWidth={80} noWrap>
                    {item.host}
                  </Td>
                  <Td small minWidth={60} noWrap>
                    {item.nome_banco_de_dados}
                  </Td>
                  <Td small minWidth={80}>
                    {item.porta}
                  </Td>
                  <Td small noWrap minWidth={80}>
                    {item.usuario}
                  </Td>
                  <Td small noWrap minWidth={80}>
                    {item.senha}
                  </Td>
                  <Td noWrap minWidth={30}>
                    <input
                      type="checkbox"
                      checked={item.bloqueado}
                      title="Bloqueado"
                      onChange={() => {
                        this.props.salvar({
                          ...item,
                          data_base_mensalidade:
                            DateFormat.formatarDataSqlParaTela(
                              item.data_base_mensalidade
                            ),
                          bloqueado: !item.bloqueado,
                        });
                      }}
                    />
                  </Td>
                  <Td alignRight minWidth={100}>
                    <ButtonTable
                      type={"warning"}
                      icon={"fas fa-pencil-alt"}
                      visible={this.state.linhaSelecionada == item.id}
                      event={() => {
                        this.props.setModoTela("cadastro", {
                          ...item,
                        });
                        this.props.initForm({
                          ...item,
                          data_base_mensalidade:
                            DateFormat.formatarDataSqlParaTela(
                              item.data_base_mensalidade
                            ),
                        });
                      }}
                    />

                    <ButtonTable
                      type={"danger"}
                      icon={"fas fa-trash-alt"}
                      visible={this.state.linhaSelecionada == item.id}
                      event={() => {
                        this.props.setModoTela("exclusao", {
                          ...item,
                        });
                        this.props.initForm({
                          ...item,
                          data_base_mensalidade:
                            DateFormat.formatarDataSqlParaTela(
                              item.data_base_mensalidade
                            ),
                        });
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </ContentCardBody>
      </ContentCard>
    );
  }
}

const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  modoTela: state.sindicato.modoTela,
  lista: state.sindicato.lista,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setModoTela, initForm, salvar, excluir, getLista },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Sindicato);
