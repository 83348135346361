import React from "react";
import Menu from "./menu";
import imagemLogo from "../../assets/images/logo_fetag.jpg";

export default (props) => (
  <aside className="main-sidebar elevation-3 sidebar-light-green">
    <a href="../../" className="brand-link navbar-light">
      <img src={imagemLogo} alt="Fetag Logo" className="brand-image" />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className="brand-text font-weight-light">
          <b>Integração Fetag</b>
        </span>
      </div>
    </a>
    <div className="sidebar">
      <Menu />
    </div>
  </aside>
);
