import React from "react";
import { Link } from "react-router-dom";

export default (props) => (
  <li className="nav-item">
    <Link
      to={props.path}
      className={`nav-link ${props.active ? "active" : ""}`}
    >
      <i className={`nav-icon ${props.icon}`} style={{ marginRight: 20 }}></i>
      <p>
        {props.label}
        <span class={`badge badge-${props.spanType} right`}>{props.span}</span>
      </p>
    </Link>
  </li>
);
